/**
 * @description 实现axios封装
 */

import { ElLoading, ElMessage } from "element-plus";
import store from "@/store"
import axios from "axios"
import router from "../router"

/* 通用配置 */
const Axios = axios.create({
  /* 实现系统的请求主要代理 */
  baseURL,
  timeout: 60000
})

const HIDE_LOADING_DELAY = 150;
const META_CODE = {
    SUCCESS: 0,
    TOKEN_EXPIRE: 10004,
    FRONT_WRAPPER_ERROR: 99999
};

let loading = null,
    hideLoadingJob = null;

function showLoading() {
    if (loading === null) {
        loading = ElLoading.service({
            fullscreen: true,
            background: "rgba(0, 0, 0, .2)"
        });
    }
}

function hideLoading() {
    if (hideLoadingJob !== null) {
        clearTimeout(hideLoadingJob);
    }
    hideLoadingJob = setTimeout(() => {
        if (loading) {
            loading.close();
            loading = null;
        }
        hideLoadingJob = null;
    }, HIDE_LOADING_DELAY);
}

/* 实现请求拦截 */
/* 1. 哪些请求不需要进行拦截 */
const nologinUrls = [
  '/user/login'
]

/**
* 请求方法封装
* @param {string | {url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', data: any, params: any}} req 请求对象
* @returns Promise
*/
async function Ajax(req) {
  const data = buildRequest(req);
  showLoading();
  try {
      let { data: response } = await Axios.request(data);
      if (!isRequestSuccess(response)) {
          handleRequestError(response);
      }
      hideLoading();
      return response;
  } catch (error) {
      hideLoading();
      if (data.isAutoHandleError === false) {
          throw error;
      }
      return handleNetworkError(error);
  }
}

function isRequestSuccess(data) {
    return data.meta.code === META_CODE.SUCCESS;
}

function handleRequestError(data) {
    if (data.meta.code === META_CODE.TOKEN_EXPIRE) {
        goLogin("登录失效,请重新登录");
    }
    ElMessage.error(data.meta.message);
}

function handleNetworkError(error) {
    ElMessage.error("网络出错，请稍候重试");
    console.log(error);
    return {
        meta: {
            code: META_CODE.FRONT_WRAPPER_ERROR
        }
    };
}

function goLogin(message) {
    ElMessage.error({ message, duration: 1000 * 2 });
    setTimeout(() => {
        router.push("/login");
    }, 1000 * 2);
}

function buildRequest(req) {
    if (typeof req === "string") {
        req = { url: req };
    }
    if (!nologinUrls.includes(req.url)) {
        let token = store.getters['user/token'];
        if (!token) {
            goLogin("用户没有正确登录,请先进行登录");
            return;
        }
        if (!req.headers) {
            req.headers = {};
        }
        req.headers['Authorization'] = 'Bear ' + token
    }
    return req;
}

export default Ajax
