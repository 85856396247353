/**
 * @description 定义请求地址常量
 */

export const LOGIN_URL = '/user/login'

export  const USER_URL = '/user'
export  const GOOD_URL = '/goods'
export  const WITHDRAW_URL = '/withdraw'
export  const ORDER_URL = '/order'
export  const ORDERCANCEL_URL = '/order/cancel'
export  const ORDERFLOW_URL = '/cart/flow'
export  const FILE_URL = '/file/base64'
export  const TRUSTEESHIP_URL = '/trusteeship'
export  const BANNER_URL = '/banner'

export const DELETE_FILE_URL = '/file/'

/** 类型接口 */
export const TYPES_URL = '/goods-types'

/** 消息接口 */
export const INFO_LIST_URL = '/info/list'
export const INFO_ADD_URL = '/info/add'
export const INFO_UPDATE_URL = '/info/update'
export const INFO_DELETE_URL = '/info/'